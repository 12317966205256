<!-- 二级路由 -->
<template>
  <div class="content" :class="{ has_breadcrumb: breadcrumbData.length > 0 }">
    <base-breadcrumb :data="breadcrumbData" slot="breadcrumb" />
    <GgPage pageType="2" :title="title">
      <template v-slot:left>
        <gg-flex-menus
          :menus="menuDatas"
          @select="menuSelect"
          :default-active="activeIndex"
        />
      </template>
      <router-view></router-view>
    </GgPage>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      menuDatas: [],
      activeIndex: "",
      title: "",
      breadcrumbData: [],
    };
  },
  computed: {
    ...mapState({
      breadcrumb: (state) => state.breadcrumb,
    }),
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      let menuDatas = to.meta.menuDatas;
      vm.menuDatas = menuDatas;
      vm.activeIndex = to.name;
      vm.title = to.meta.pageTitle;
      vm.breadcrumbData = vm.breadcrumb.breadcrumbData || [];
    });
  },
  methods: {
    menuSelect(key) {
      console.log(this.$route);
      this.activeIndex = key;
      // this.$router.push(`${this.$route.matched[0].path}/${key}`);
      this.$router.push({ name: key });
    },
  },
  created() {},
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
</style>